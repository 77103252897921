/// <reference path="o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as DynamicWorkboxStrategyModule from 'o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { O365OfflineSyncStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.O365OfflineSyncStrategy.d.ts')>('o365.pwa.modules.sw.strategies.O365OfflineSyncStrategy.ts');
    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts");

    class DynamicWorkboxStrategy extends self.workbox.strategies.Strategy implements DynamicWorkboxStrategyModule.DynamicWorkboxStrategy {

        constructor(options: DynamicWorkboxStrategyModule.IDynamicWorkboxStrategy = {}) {
            super(options);
        }

        async _handle(request: Request, handler: typeof self.workbox.strategies.StrategyHandler) {
            
            try {
                if (handler.event.preloadResponse) {
                    await handler.event.preloadResponse;
                }

                let strategy = request.headers.get('o365-workbox-strategy') as DynamicWorkboxStrategyModule.WorkboxStrategy | null;

                if (strategy === null) {
                    const clientId = handler.event.clientId;

                    const client = await self.clients.get(clientId);

                    let requestReferer = client?.url ?? request.url;

                    if (requestReferer?.length > 0) {
                        const requestRefererUrl = new URL(requestReferer);
                        const appId = requestRefererUrl.pathname.split('/').pop() ?? '';

                        let idbApp = await IndexedDBHandler.getApp(appId);
                        let idbPwaState = await idbApp?.pwaState;

                        const isAppInstalled = idbPwaState?.isAppInstalled ?? false;
                        const isDebugUi = idbPwaState?.debugUi ?? false;

                        if (isAppInstalled === true && isDebugUi === false) {
                            strategy = 'CacheOnly';
                        } else {
                            strategy = 'NetworkOnly';
                        }
                    } else {
                        strategy = 'NetworkOnly';
                    }
                }

                const strategyOptions = {
                    cacheName: this.cacheName,
                    fetchOptions: this.fetchOptions,
                    matchOptions: this.matchOptions,
                    plugins: this.plugins
                };

                const handleOptions = {
                    event: handler.event,
                    request: request
                };

                let response;

                switch (strategy) {
                    case 'CacheFirst':
                        response = await new self.workbox.strategies.CacheFirst(strategyOptions).handle(handleOptions);
                        break;
                    case 'CacheOnly':
                        response = await new self.workbox.strategies.CacheOnly(strategyOptions).handle(handleOptions);
                        break;
                    case 'NetworkFirst':
                        response = await new self.workbox.strategies.NetworkFirst(strategyOptions).handle(handleOptions);
                        break;
                    case 'NetworkOnly':
                        response = await new self.workbox.strategies.NetworkOnly(strategyOptions).handle(handleOptions);
                        break;
                    case 'StaleWhileRevalidate':
                        response = await new self.workbox.strategies.StaleWhileRevalidate(strategyOptions).handle(handleOptions);
                        break;
                    case 'O365-Offline-Sync':
                        response = await new O365OfflineSyncStrategy(strategyOptions).handle(handleOptions);
                        break;
                    default:
                        response = new Response(null, {
                            status: 500,
                            statusText: 'Invalid strategy'
                        });
                        break;
                }

                if (response.type === 'opaque') {
                    return response;
                }

                const headersCopy = new Headers(response.headers);

                headersCopy.set('X-O365-Strategy', strategy);
                headersCopy.set('X-O365-CacheName', this.cacheName);

                if (this.cacheName === 'app_html') {
                    headersCopy.append('Server-Timing', 'O365-Service-Worker');
                }

                const arrayBuffer = await response.arrayBuffer();

                return new Response(arrayBuffer, {
                    status: response.status,
                    statusText: response.statusText,
                    headers: headersCopy
                });
            } catch (reason) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                
                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error'
                });
            }
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts')>({ DynamicWorkboxStrategy, self });
})();
